<template>
  <div>
    <title>SIM INVENTORY ~ DETAILS ITEM OUTGOING DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Detailed Item Outgoing Data
        <br />
        <h4>
          The following is a list of detailed item outgoing transaction data
          lists
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <!-- <div class="row">
        <div class="col-md-12">
          <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
              <li class="active">
                <a href="#tab_1" data-toggle="tab"
                  >Detailed Items Outgoing Data</a
                >
              </li>
              <li>
                <a href="#tab_2" data-toggle="tab">Stock Data</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="tab_1">
                <div class="box-header">
                  <div class="box-header with-border">
                    <i class="fa fa-list"></i>
                    <h3 class="box-title">Detailed Items Outgoing Data</h3>
                  </div>
                  <div class="box-body">
                    <div class="row">
                      <div class="col-xs-3">
                        Show &nbsp; :
                        <select
                          v-model="pageSize"
                          @change="handlePageSizeChange($event)"
                        >
                          <option
                            v-for="size in pageSizes"
                            :key="size"
                            :value="size"
                          >
                            {{ size }}
                          </option>
                        </select>
                        results
                      </div>
                      <div class="col-xs-5">
                        <input
                          type="text"
                          autocomplete="off"
                          placeholder="Search by Outgoing Number"
                          class="form-control"
                          v-model="namesearch"
                        />
                      </div>
                      <div class="col-xs-1">
                        <button
                          @click="searchdata()"
                          type="button"
                          class="btn btn-info"
                        >
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />
                  <h3 class="box-title">Outgoing Details</h3>
                </div>

                
                <div class="box-body">
                  <div class="table-responsive">
                    <table
                      id="mydata"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Outgoing Number</th>
                          <th>Receipt Number</th>
                          <th>Item Code</th>
                          <th>Qty</th>
                          <th>Invoice Price</th>
                          <th>Unit Price</th>
                          <th>Notes</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(classdata, index) in list_paged"
                          :key="classdata.id"
                        >
                          <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                          <td>{{ classdata.outgoing_number }}</td>
                          <td>{{ classdata.receipt_number }}</td>
                          <td>{{ classdata.kode_barang }}</td>
                          <td>
                            {{ classdata.qty }} &nbsp; {{ classdata.unit }}
                          </td>
                          <td>{{ classdata.harga_invoice }}</td>
                          <td>{{ classdata.harga_satuan }}</td>
                          <td>{{ classdata.notes }}</td>
                          <td>
                            <router-link
                              :to="'/edit-outgoingdetail/' + classdata.id"
                            >
                              <button
                                title="Edit Item Outgoing Details"
                                class="btn btn-info"
                              >
                                <i class="fa fa-edit"></i>
                              </button> </router-link
                            >&nbsp;

                            <button
                              title="Delete Item Outgoing Details"
                              @click="deleteData(classdata.id)"
                              class="btn btn-danger"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="nmsrc">
                      <v-pagination
                        v-model="page"
                        :pages="count"
                        :range-size="1"
                        active-color="#DCEDFF"
                        @update:modelValue="handlePageChange"
                      />
                    </div>
                    <div v-else></div>
                  </div>
                </div>
              </div>

              <div class="tab-pane" id="tab_2">
                <div class="box-header">
                  <div class="box-header with-border">
                    <i class="fa fa-list"></i>
                    <h3 class="box-title">Stock Data</h3>
                  </div>
                  <div class="box-body">
                    <div class="row">
                      <div class="col-xs-3">
                        Show &nbsp; :
                        <select
                          v-model="pageSize2"
                          @change="handlePageSizeChange2($event)"
                        >
                          <option
                            v-for="size2 in pageSizes2"
                            :key="size2"
                            :value="size2"
                          >
                            {{ size2 }}
                          </option>
                        </select>
                        results
                      </div>
                      <div class="col-xs-5">
                        <input
                          type="text"
                          autocomplete="off"
                          placeholder="Search by Outgoing Number"
                          class="form-control"
                          v-model="namesearch2"
                        />
                      </div>
                      <div class="col-xs-1">
                        <button
                          @click="searchdataStok()"
                          type="button"
                          class="btn btn-info"
                        >
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />
                  <h3 class="box-title">Stock Data</h3>
                </div>

                <div class="box-body">
                  <div class="table-responsive">
                    <table
                      id="mydata"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Outgoing Number</th>
                          <th>Order Number</th>
                          <th>Out Date</th>
                          <th>Buyer Name</th>
                          <th>Product Name</th>
                          <th>Process Name</th>
                          <th>Item Code</th>
                          <th>Stock</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(classdatastok, index2) in list_paged2"
                          :key="classdatastok.id"
                        >
                          <td>{{ index2 + 1 + (page - 1) * pageSize2 }}</td>
                          <td>{{ classdatastok.outgoing_number }}</td>
                          <td>{{ classdatastok.order_number }}</td>
                          <td>{{ classdatastok.date_out }}</td>
                          <td>{{ classdatastok.nama_buyer }}</td>
                          <td>{{ classdatastok.nama_produk }}</td>
                          <td>{{ classdatastok.nama_proses }}</td>
                          <td>{{ classdatastok.kode_barang }}</td>
                          <td>{{ classdatastok.stok }}</td>
                          <td>
                            <router-link
                              :to="
                                '/add-prosesproduksi-detailmaterial/' +
                                classdatastok.id
                              "
                            >
                              <button
                                title="Add Production Process Material Details"
                                class="btn btn-success"
                              >
                                <i class="fa fa-plus"></i>
                              </button> </router-link
                            >&nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="nmsrc">
                      <v-pagination
                        v-model="page"
                        :pages="count"
                        :range-size="1"
                        active-color="#DCEDFF"
                        @update:modelValue="handlePageChange2"
                      />
                    </div>
                    <div v-else></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">
                  Detailed Items Outgoing Data
                  <b style="color: red"> {{ outnumb }} </b>
                </h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-3">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Item Code"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-1">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- <h3 class="box-title">Outgoing Details</h3> -->
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <input
                type="hidden"
                class="form-control"
                readonly
                name="outnumb"
                v-model="outnumb"
                id="outnumb"
              />
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <!-- <th>Receive Number</th> -->
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Supplier</th>
                      <!-- <th>Type</th> -->
                      <th>Qty</th>
                      <!-- <th>Notes</th> -->
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.id }}</td> -->

                      <!-- <td>{{ classdata.receipt_number }}</td> -->
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.nama_barang }}</td>
                      <td>
                        {{ classdata.nama_supplier }} ({{
                          classdata.receipt_date
                        }})
                      </td>
                      <!-- <td>{{ classdata.tipe }}</td> -->
                      <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                      <!-- <td>{{ classdata.notes }}</td> -->
                      <td>
                        <!-- <router-link
                          :to="'/edit-outgoingdetail/' + classdata.id"
                        >
                          <button
                            title="Edit Item Outgoing Details"
                            class="btn btn-info"
                          >
                            <i class="fa fa-edit"></i>
                          </button> </router-link
                        >&nbsp; -->

                        <button
                          title="Delete Item Outgoing Details"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Outgoing Number</th>
                      <th>Receipt Number</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Supplier</th>
                      <th>Qty</th>
                      <!-- <th>Notes</th> -->
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="8" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "klasifikasiartikel",
  components: {
    VPagination,
  },
  data() {
    return {
      isShow: false,
      isExist: true,
      loading: false,
      namesearch: "",
      outnumb: "",
      namesearch2: "",
      list_paged: [],
      list_paged2: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSize2: 10,
      pageSizes: [10, 50, 100, 500],
      pageSizes2: [10, 50, 100, 500],
    };
  },
  created() {
    this.fetchData();
    this.outnumb = this.$route.params.id;
    // this.fetchDataStok();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if(this.namesearch == '') {
        this.fetchData();
      } else {
        this.searchdata()
      }
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if(this.namesearch == '') {
        this.fetchData();
      } else {
        this.searchdata()
      }
    },

    // handlePageChange2(value) {
    //   this.page = value;
    //   this.pagestat = 1;
    //   // window.scrollTo({ top: 0, behavior: "smooth" });
    //   this.fetchDataStok();
    // },

    // handlePageSizeChange2(event) {
    //   this.pageSize2 = event.target.value;
    //   this.page = 1;
    //   this.fetchDataStok();
    // },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "outgoing_d/getalldataoutgoing_d?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&outgoing_number=" +
        this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          // console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          })
        });
    },
    /*  async fetchDataStok() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "proses_produksi_d_material/getalldatastok_material_di_produksi?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.list_paged2 = resp.data.data;
          this.count = resp.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          // console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    }, */
    searchdata() {
      this.loading = true;

      const params = this.getRequestParams(this.page);
      //const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/outgoing_h/getalldataoutgoing_h?cari=" + this.namesearch+"&length="+this.pageSize;
      const urlAPIget =
        this.$apiurl +
        "outgoing_d/getalldataoutgoing_d?cari=" +
        this.namesearch +
        "&length=" +
        this.pageSize +
        "&outgoing_number=" +
        this.$route.params.id;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          })
        });
    },
    /* searchdataStok() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch2 == "") {
        window.location.reload();
        this.loading = true;
        this.fetchDataStok();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/outgoing_h/getalldataoutgoing_h?cari=" + this.namesearch+"&length="+this.pageSize;
        const urlAPIget =
          this.$apiurl +
          "proses_produksi_d_material/getalldatastok_material_di_produksi?cari=" +
          this.namesearch +
          "&length=" +
          this.pageSize;
        axios
          .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
          .then((resp) => {
            this.list_paged2 = resp.data.data;
            this.count = resp.data.datatotalcount;
            this.loading = false;
            this.nmsrc = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
            }).then(function (isConfirm) {
              if (isConfirm) {
                window.location.href = "/";
              }
            });
          });
      }
    }, */
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/outgoing_h/hapusoutgoing_h/" + id;
          const urlAPIbrgdel =
            this.$apiurl + "outgoing_d/hapusoutgoing_d/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin,
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully",
                }).then(function () {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed",
                }).then(function () {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
              }).then(function (isConfirm) {
                if (isConfirm) {
                  window.location.href = "/";
                }
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
